<template>
  <section v-if="procedure">
    <article class="card">
      <div class="card__padding">
        <h3 class="title__h3 bold">
          {{ t('project.settings_duration_title') }}
        </h3>

        <form-notification
          class="margin__t--12"
          :keys="error"
        />

        <fieldset class="margin__t--18">
          <label
            for="start_date"
            class="input__label input__label--small"
          >
            {{ t('literal.project_starts_on') }}
          </label>

          <input-datepicker
            id="start_date"
            class="margin__t--6"
            v-model="startDate"
            :show-time="true"
            :available-dates="availableStartDates"
            :open-date="new Date()"
            :v="v$.start_date"
          />
        </fieldset>

        <fieldset class="margin__t--18">
          <label
            for="end_date"
            class="input__label input__label--small"
          >
            {{ t('literal.project_ends_on') }}
          </label>

          <input-datepicker
            id="end_date"
            class="margin__t--6"
            v-model="endDate"
            :show-time="true"
            :available-dates="availableEndDates"
            :open-date="new Date()"
            :v="v$.end_date"
          />
        </fieldset>

        <app-save-button
          class="margin__t--24"
          name="save_end_date"
          :is-loading="loader"
          :is-disabled="loader"
          :is-saved="!isUnsaved"
          @click="handleClickSave()"
        />
      </div>
    </article>

    <modal-confirm
      v-if="show_unsaved_modal"
      @close="cancelLeavingPage()"
      @confirm="leavePage()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('literal.leave_page_without_saving') }}</p>
        </div>
      </template>
    </modal-confirm>
  </section>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useUnsaved } from '@/composables/app/useUnsaved'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { useVuelidate } from '@vuelidate/core'
import { minDate, maxDate } from '@/utils/validators'
import { scrollTo } from '@/utils/utils'

import InputDatepicker from '&/atoms/InputDatepicker'
import AppSaveButton from '&/molecules/AppSaveButton'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))
const ModalConfirm = defineAsyncComponent(() => import('&/organisms/ModalConfirm'))

const props = defineProps({ projectSlug: { type: String, required: true } })

const { projectSlug } = toRefs(props)

const { t } = useI18n()
const { getProject, fetchProject, $resetStore } = useProjectStore()
const { getProcedure, patchProcedure } = useProcedureStore()
const { postSideNotification } = useNotificationStore()

const { project } = getProject({ id: projectSlug.value })
const { procedure, clonedProcedure, loader, error } = getProcedure({ id: project.value?.procedures.donation?.id, dependencies: [project] })

useHead({ title: computed(() => `${t('literal.duration')} / ${t('literal.settings')} / ${project.value?.name}`) })
const { show_unsaved_modal, isUnsaved, cancelLeavingPage, leavePage } = useUnsaved({ condition: computed(() => JSON.stringify(procedure.value) !== JSON.stringify(clonedProcedure.value)) })

const createdAt = computed(() => project.value?.created_at)

const startDate = computed({
  get: () => clonedProcedure.value?.start_date,
  set: date => clonedProcedure.value.start_date = date
})

const endDate = computed({
  get: () => clonedProcedure.value?.end_date,
  set: date => clonedProcedure.value.end_date = date
})

const availableStartDates = computed(() => ({
  start: createdAt.value ? new Date(createdAt.value) : new Date(),
  end: new Date(endDate.value)
}))

const availableEndDates = computed(() => ({
  start: new Date(startDate.value),
  end: new Date(new Date().setFullYear(new Date().getFullYear() + 10))
}))

const validations = computed(() => ({
  start_date: { minDate: minDate(availableStartDates.value.start), maxDate: maxDate(availableStartDates.value.end) },
  end_date: { minDate: minDate(availableEndDates.value.start), maxDate: maxDate(availableEndDates.value.end) }
}))

const v$ = useVuelidate(validations, clonedProcedure)

const handleClickSave = () => {
  v$.value.$touch()

  if (v$.value.$error) return scrollTo('.input--errors')

  patchProcedure({ id: procedure.value.id, params: { start_date: startDate.value, end_date: endDate.value } })
    .then(() => {
      $resetStore(['projects'])

      fetchProject({ id: project.value.slug })
      postSideNotification({ text: 'literal.modifications_have_been_saved' })
    })
}
</script>