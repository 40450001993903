<template>
  <div v-if="(isShowed('text_members') && text) || isShowed('members_list')">
    <h3
      class="title color--lg"
      v-if="isShowed('text_members') && text"
    >
      {{ text }}
    </h3>

    <ul
      class="members"
      v-if="isShowed('members_list')"
    >
      <li
        class="members__li"
        v-for="(member, index) in members"
        :style="{ zIndex: users.length - index }"
        :key="member.id"
      >
        <app-tooltip>
          <button
            @click.prevent="openModal"
            class="d-block"
          >
            <user-profile-img
              class="members__img"
              :size="4"
              :avatar="member.avatar"
            />
          </button>

          <template #popper>
            {{ member.public_name }}
          </template>
        </app-tooltip>
      </li>

      <li
        class="members__li"
        :style="{ zIndex: 0 }"
        v-if="users.length > members.length"
      >
        <app-tooltip :triggers="['hover']">
          <button
            class="members__img members__more"
            @click.prevent="openModal"
          >
            +{{ users.length - members.length }}
          </button>

          <template #popper>
            {{ t('literal.all_team_members') }}
          </template>
        </app-tooltip>
      </li>

      <li
        class="margin__l--12"
        v-if="isAllowedInviteMembers"
      >
        <app-tooltip :triggers="['hover']">
          <router-link
            class="invite-member link__color--prim d-block"
            :to="{ name: 'ProjectSettings', query: { tab: 'members' } }"
            :aria-label="t('literal.invite_new_members')"
          >
            <figure class="invite-member__icon">
              <fa-icon :icon="['fal', 'user-plus']" />
            </figure>
          </router-link>

          <template #popper>
            {{ t('literal.invite_new_members') }}
          </template>
        </app-tooltip>
      </li>
    </ul>

    <members-modal
      :project="project"
      v-if="show_modal"
      @close="closeModal"
    />
  </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { useModals } from '@/composables/app/useModals'
import { get as _get } from 'lodash'

import UserProfileImg from '&/atoms/UserProfileImg'
import AppTooltip from '@/components/atoms/AppTooltip'

const MembersModal = defineAsyncComponent(() => import('@/components/modules/project/PublicMembersModal'))

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { column, block } = toRefs(props)

const { authUser } = storeToRefs(useAuthenticationStore())
const { translate, t } = useI18n()
const { objects, isShowed, getText } = useBlock(block)
const { show_modal, openModal, closeModal } = useModals()

const project = computed(() => _get(objects.value, 'project'))
const text = computed(() => translate(getText('members')))
const users = computed(() => _get(project.value, 'users', []).toReversed())
const members = computed(() => column.value?.is_small ? users.value.slice(0, 2) : users.value.slice(0, 12))
const isAllowedInviteMembers = computed(() => project.value?.isAuthorized(authUser.value) && _get(project.value, 'campaign.project_display_settings.invite_project_members', false))
</script>

<style lang="scss" scoped>
.title {
  font-size: rem(14px);
}

.members {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  padding-left: 12px;

  &__li {
    position: relative;
    margin-left: -12px;
  }

  &__img {
    display: block;
    border: 3px solid $vdw;
    border-radius: 50%;
  }

  &__more {
    font-size: em(14px);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $vlg;
    font-weight: bold;
    width: 48px;
    height: 48px;
  }
}

.invite-member {
  &__icon {
    transition: all .3s;
    width: 42px;
    height: 42px;
    border: 1px dashed currentColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>